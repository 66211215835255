export const admin = [
  {
    path: "/admin_dashboard",
    name: "admin_dashboard",
    component: () => import("../views/Dashboard/Admin/Overview.vue"),
  },
  {
    path: "/admin_management",
    name: "admin_management",
    component: () => import("../views/Dashboard/Admin/AdminManagement.vue"),
  },
  {
    path: "/pending_approvals",
    name: "pending_approvals",
    component: () => import("../views/Dashboard/Admin/PendingApprovals.vue"),
  },
  {
    path: "/private_order",
    name: "private_order",
    component: () => import("../views/Dashboard/Admin/PrivateOrder.vue"),
  },
  {
    path: "/online_order",
    name: "online_order",
    component: () => import("../views/Dashboard/Admin/OnlineOrders.vue"),
  },
  {
    path: "/customer_management",
    name: "customer_management",
    component: () => import("../views/Dashboard/Admin/CustomerManagement.vue"),
  },
  {
    path: "/anchor_management",
    name: "anchor_management",
    component: () => import("../views/Dashboard/Admin/AnchorManagement.vue"),
  },
  {
    path: "/interstate_operations",
    name: "interstate_operations",
    component: () => import("../views/Dashboard/Admin/InterstateOperations.vue"),
  },
  {
    path: "/phone_orders",
    name: "phone_orders",
    component: () => import("../views/Dashboard/Admin/PhoneOrders.vue"),
  },
  {
    path: "/dedicated_company",
    name: "dedicated_company",
    component: () => import("../views/Dashboard/Admin/DedicatedCompany.vue"),
  },
  {
    path: "/riders_management",
    name: "riders_management",
    component: () => import("../views/Dashboard/Admin/RiderManagement.vue"),
  },
  {
    path: "/carriers_management",
    name: "carriers_management",
    component: () => import("../views/Dashboard/Admin/Carriers.vue"),
  },
  {
    path: "/affiliate_management",
    name: "affiliate_management",
    component: () => import("../views/Dashboard/Admin/AffiliateManagement.vue"),
  },
  {
    path: "/company_management",
    name: "company_management",
    component: () => import("../views/Dashboard/Admin/CompanyManagement.vue"),
  },
  {
    path: "/admin_heatmap",
    name: "admin_heatmap",
    component: () => import("../views/Dashboard/Admin/HeatMap.vue"),
  },
  {
    path: "/admin_roles",
    name: "admin_roles",
    component: () => import("../views/Dashboard/Admin/Roles.vue"),
  },
];
