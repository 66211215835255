
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: String,
    subText: String,
    reverse: String,
    btnTitle: String,
    src_image: String,
  },
  name: "horizontalcard2",
  components: {},
});
