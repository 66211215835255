<template>
  <div className="sm:w-[48%] w-full min-w-[200px] mb-3">
    <div
      @click="togglePopup"
      registerValue="pickupLocation"
      class="w-full cursor-pointer flex items-center font-[500] text-[16px] border-[#690722] placeholder-[#690722] pl-5 border bg-transparent h-[56px] rounded-[50px]"
    >
      {{ title }}
    </div>
    <div class="flex justify-end">
      <span class="bottom-[2rem] right-[1.3rem] relative">
        <svg
          width="20"
          height="11"
          viewBox="0 0 20 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1.02002L10 10.02L19 1.02002"
            stroke="black"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
    <location-dropdown :show="show" />
  </div>
</template>

<script>
import LocationDropdown from "./LocationDropdown.vue";
export default {
  components: { LocationDropdown },
  name: "CustomDropDown",
  data() {
    return {
      show: false,
    };
  },
  props: {
    title: String,
    title: String,
  },
  methods: {
    togglePopup() {
      this.show = this.show ? false : true;
    },
  },
};
</script>