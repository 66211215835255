
import { defineComponent } from "vue";
import CustomButton from "../Shared/CustomButton.vue";

export default defineComponent({
  name: "Navbar",
  components: {
    CustomButton,
  },
  data() {
    return {
      sidebarDisplay: false,
    };
  },
  methods: {
    openSidebar() {
      this.sidebarDisplay = true;
    },
    closeSidebar() {
      console.log(this.sidebarDisplay);
      this.sidebarDisplay = false;
    },
  },
  mounted() {},
});
