import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bg-[#ffffff] md:w-[88%] m-auto" }
const _hoisted_2 = { class: "w-full sm:w-[28%] pb-9 sm:pb-0 sm:mr-[2rem] md:block flex justify-center sm:mr-0" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "sm:text-[32px] text-[20px] font-[700] leading-[57px] sm:pb-[2rem]" }
const _hoisted_5 = { class: "text-[#617686] text-[16px]" }
const _hoisted_6 = { class: "mt-7" }
const _hoisted_7 = {
  key: 0,
  class: "",
  id: "partners"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "bg-[#690722] h-[57px] !border-2 rounded-full text-white border-none px-10 py-2 cursor-pointer font-bold hover:bg-none hover:border-2 hover:border-white hover:text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(
        _ctx.reverse
          ? 'flex flex-row mx-auto max-w-7xl px-2 sm:px-6 justify-between items-center lg:px-8 sm:pt-[2rem] flex-wrap sm:flex-nowrap sm:pb-[2rem] pt-[2rem] pb-[1rem] sm:flex-row-reverse'
          : 'flex flex-row mx-auto max-w-7xl px-2 sm:px-6 justify-between items-center lg:px-8 sm:pt-[1rem] flex-wrap sm:flex-nowrap sm:pb-[6rem] pt-[2rem] pb-[2rem]'
      )
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: require(`@/assets/images/${_ctx.src_image}`),
          class: "rounded-[2rem]",
          alt: "blockImg"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(
          _ctx.reverse
            ? 'md:pl-[3rem] md:pr-[3rem] w-full sm:w-[70%] z-0 text-[#02021E] text-center sm:text-left'
            : 'md:pl-[3rem] w-full sm:w-[70%] z-0 text-[#02021E] text-center sm:text-left '
        )
      }, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.subText), 1),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.btnTitle == 'Become a Delivery Agent')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7))
            : _createCommentVNode("", true),
          _createElementVNode("a", {
            href: _ctx.btnTitle == 'Become a Delivery Agent'?'/signup':'/signup?id=partner'
          }, [
            _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.btnTitle), 1)
          ], 8, _hoisted_8)
        ])
      ], 2)
    ], 2)
  ]))
}