
import { defineComponent } from "vue";

export default defineComponent({
  name: "Card3",
  props: {
    title: String,
    src_image: String,
    content: String,
  },
  components: {},
});
