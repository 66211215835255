
import { defineComponent } from "vue";
import Card1 from "../Shared/Cards/Card1.vue";

export default defineComponent({
  name: "HowItWorks",
  components: {
    Card1,
  },
});
