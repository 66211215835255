
//@ts-ignore
import { defineComponent, ref } from "vue";
import Card2 from "../Shared/Cards/Card2.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

const vueSlickCarouselConfig = {
  infinite: false,
  speed: 600,
  variableWidth: false,
  adaptiveHeight: false,
  autoplay: false,
  slidesToScroll: 1,
  swipeToSlide: true,
  slidesToShow: 3,
  responsive: [
    { breakpoint: 1280, settings: { slidesToShow: 2 } },
    { breakpoint: 640, settings: { slidesToShow: 1 } },
  ],
};

export default defineComponent({
  name: "Testimonials",
  components: {
    Card2,
    VueSlickCarousel,
  },
  setup() {
    const sliderEl = ref();

    function showPrev() {
      sliderEl.value?.prev();
    }

    function showNext() {
      sliderEl.value?.next();
    }

    return { vueSlickCarouselConfig, sliderEl, showPrev, showNext };
  },
});
