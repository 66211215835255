
import { defineComponent } from "vue";
import HomePage from "../components/PageComponents/HomePage.vue"; // @ is an alias to /src

export default defineComponent({
  name: "HomeView",
  components: {
    HomePage,
  },
});
