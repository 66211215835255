import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import VueGoogleHeatmap from "vue-google-heatmap";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";

// Replace 'YOUR_GOOGLE_API_KEY' with your actual Google API key
const GOOGLE_API_KEY = "AIzaSyDeyh6RIOAfQIkcXeL7jKnIs2PQJ1Cfcxo";

// You can create a separate file to manage your API configuration
// For example, create a file named 'googleAPI.js'
export const googleAPIConfig = {
  load: {
    key: GOOGLE_API_KEY,
  },
};

createApp(App)
  .use(store)
  .use(router)
  .use(Vue3Toastify, {
    autoClose: 3000,
  } as ToastContainerOptions)  
  .use(VueGoogleHeatmap, {
    apiKey: GOOGLE_API_KEY,
  }) // Providing the API key globally via Vue's provide/inject
  .mount("#app")