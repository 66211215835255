<template>
  <div class="overflow-hidden">
    <Navbar />
    <div class="mainBg">
      <div
        class="text-white md:w-[60%] m-auto relative top-[8rem] sm:top-0 md:top-0"
      >
        <div
          class="sm:text-[57px] text-[20px] md:mt-[12rem] leading-[1.2] mb-5 font-extrabold"
        >
          Dellyman is expanding to major cities of Nigeria
        </div>
        <div class="sm:text-[22px] text-[16px] mb-5 leading-[1]">
          Join our Network of Agents and Anchor Partners and earn attractive
          recurring revenue
        </div>
        <div class="text-center mt-7">
          <a href="/signup">
            <button
              class="bg-[#FFB13C] h-[57px] !border-2 w-[343px] rounded-full text-black border-none px-10 py-2 cursor-pointer font-bold hover:bg-none hover:border-2 hover:border-white hover:text-white"
            >
              Apply Here
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="" id="agents"></div>
    <div class="pt-[8rem] pb-[2rem]">
      <horiontal-card-2
        title="Who are Delivery Agents?"
        subText="Our Agents are the foot soldiers who ensure the prompt doorstep delivery of customer packages in the local environment they operate. With very strong local knowledge, they are able to reach customers even in the most difficult of circumstances so that delivery of customer orders do not fail. If you are looking for a consistent recurring revenue or a side hustle, this is the gig for you."
        reverse=""
        btnTitle="Become a Delivery Agent"
        src_image="itemonland2.png"
      />
      <div class="" id="collectionAgents"></div>
      <div class="">
        <horiontal-card-2
          title="Who are Anchor Partners?"
          subText="Anchor Partners represent the Dellyman brand in their territories, local governments or states. Think of Anchor Partners as franchisees. They receive the training and support to operate as an extension of the Dellyman operational team and in return earn a rewarding commission on every delivery order executed by Riders and Partners onboarded by them and/or from order booked by their team on the Dellyman platform."
          reverse=""
          btnTitle="Become an Anchor Partner"
          src_image="itemonland.png"
        />
      </div>
      
      <div class="">
        <horiontal-card-3
          title="Who are Collection Agents?"
          subText="Collection Agents are individuals or businesses who own shops (called Collection Centres) around busy markets and shopping malls, so merchants can drop off their packages where they are booked and ultimately mopped to Dellyman Fulfilment Centres. The packages booked  by these Collection Agents will be billed to the merchants at a flat rate anywhere in the city. In return, Collection Agents receive up to 25% discount on every package booked at the Collection Centre."
          reverse=""
          btnTitle="Become a Collection Agent"
          src_image="itemonland.png"
        />
      </div>
    </div>
    <Partners />
    <Marquee />
    <Clients />
    <OurApp />
    <Footer />
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import Navbar from "./Shared/Navbar.vue";
import HeroSection1 from "./HeroSection/HeroSection1.vue";
import HowItWorks from "./HeroSection/HowItWorks.vue";
import WhoWeAre from "./HeroSection/WhoWeAre.vue";
import GetEstimate from "./HeroSection/GetEstimate.vue";
import FAQ from "./HeroSection/FAQ.vue";
import Testimonials from "./HeroSection/Testimonials.vue";
import LatestUpdates from "./HeroSection/LatestUpdates.vue";
import Partners from "./HeroSection/Partners.vue";
import Marquee from "./HeroSection/Marquee.vue";
import Clients from "./HeroSection/Clients.vue";
import OurApp from "./HeroSection/OurApp.vue";
import Footer from "./HeroSection/Footer.vue";
import TrackDelivery from "./HeroSection/TrackDelivery.vue";
import HoriontalCard2 from "./HeroSection/HoriontalCard2.vue";
import HoriontalCard3 from "./HeroSection/HoriontalCard3.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    Navbar,
    HeroSection1,
    WhoWeAre,
    HowItWorks,
    GetEstimate,
    FAQ,
    Testimonials,
    LatestUpdates,
    Partners,
    Marquee,
    Clients,
    OurApp,
    Footer,
    TrackDelivery,
    HoriontalCard2,
    HoriontalCard3
  },
});
</script>
<style scoped lang="css">
.mainBg {
  background: url("@/assets/images/mainBg.jpg");
  height: 700px;
  padding-top: 10rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.mainBg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
  height: 700px;
}
.mainBg div ::before {
  background: none;
}
.text-white {
  position: relative;
  z-index: 1; /* Ensures that the text appears above the overlay */
}
@media (max-width: 700px) {
  .mainBg {
    background-position: bottom;
  }
}
</style>
