<template>
  <div
    class="searcharea h-[402px] z-[2] overflow-scroll px-[24px] py-[20px] mt-2 w-[295px] bg-[#f5f5f5] rounded-[12px] shadow-md absolute"
    v-if="show"
  >
    <input
      type="text"
      placeholder="Search for Zones"
      class="placeholder-[#B0B0B0] placeholder-[12px] rounded-[2px] border-neutral-200 border-2 bg-[transparent] focus:border-neutral-200 focus:ring-0 h-[40px] w-full"
      v-model="searchQuery"
    />
    <div class="">
      <div
        v-for="(item, index) in filteredPackageItems"
        :key="index"
        class="py-[1.8rem] my-[1.8rem] border-t-[#69072357] border-t"
      >
        <div class="flex justify-between items-center mb-6">
          <span class="text-[16px] text-[#690722] font-bold">{{
            item.name
          }}</span>
          <span
            @click="toggleSelected(item)"
            class="text-white flex items-center justify-center w-[98px] cursor-pointer font-bold"
            :class="{
              'bg-[#F54748]': !item.selected,
              'bg-[#32C072]': item.selected,
              'rounded-[5px]': true,
              'text-[10px]': true,
              'h-[26px]': true,
              'p-2': true,
            }"
          >
            {{ item.selected ? "Selected" : "Select This Zone" }}
          </span>
        </div>
        <div class="flex flex-wrap">
          <span
            v-for="(location, locationIndex) in item.location"
            :key="locationIndex"
            class="mr-1 text-[#2C2C2C] flex items-center justify-center cursor-pointer font-bold bg-transparent border rounded-[5px] text-[10px] h-[26px] p-2"
          >
            {{ location }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LocationDropdown",
  data() {
    return {
      packageItems: [
        {
          name: "Mainland",
          location: ["Ejigbo", "Abc", "Maryland", "Oshodi"],
          selected: false,
        },
        {
          name: "Victoria Island",
          location: ["Ejigbo", "Abc", "Maryland", "Oshodi"],
          selected: false,
        },
        {
          name: "Ijaiye",
          location: ["Iyana ipaja", "Ikotun", "Maryland", "Oshodi"],
          selected: false,
        },
      ],
      searchQuery: "",
    };
  },
  props: {
    show: Boolean,
  },
  methods: {
    toggleSelected(item) {
      item.selected = !item.selected;
    },
    submitSelectedItems() {
      const selectedItems = this.packageItems.filter((item) => item.selected);
      // Here, you can perform an action with the selected items, such as sending them to the backend
      console.log("Selected Items:", selectedItems);
      // Replace the console.log statement with your API call or desired action
    },
  },
  computed: {
    filteredPackageItems() {
      if (!this.searchQuery) {
        return this.packageItems;
      } else {
        const query = this.searchQuery.toLowerCase();
        return this.packageItems.filter((item) => {
          return (
            item.name.toLowerCase().includes(query) ||
            item.location.some((location) =>
              location.toLowerCase().includes(query)
            )
          );
        });
      }
    },
  },
};
</script>