import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { admin } from "./admin";
import { user } from "./user";

const routes: Array<RouteRecordRaw> = [
  ...admin,
  ...user,
  {
    path: "/",
    name: "home",
    props: true,
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/SignUpView.vue"),
  },
  {
    path: "/collection_agent_reg",
    name: "collection_agent_reg",
    component: () => import("../components/PageComponents/Authentication/CollectionAgent1.vue"),
  },
  {
    path: "/verification",
    name: "verification",
    component: () =>
      import("../components/PageComponents/Authentication/PartnerReg2.vue"),
  },
  {
    path: "/verification_v2",
    name: "verification_v2",
    component: () =>
      import("../components/PageComponents/Authentication/CollectionAgent2.vue"),
  },
  {
    path: "/partnersform",
    name: "partnersform",
    component: () =>
      import("../components/PageComponents/Authentication/PartnerReg3.vue"),
  },
  {
    path: "/collectionagent",
    name: "collectionagent",
    component: () =>
      import("../views/Dashboard/User/CollectionAgentForm.vue"),
  },
  {
    path: "/samedaydelivery",
    name: "samedaydelivery",
    component: () => import("../views/SameDayDeliveryView.vue"),
  },
  {
    path: "/scheduleddelivery",
    name: "scheduleddelivery",
    component: () => import("../views/ScheduledDeliveryView.vue"),
  },
  {
    path: "/payments",
    name: "payments",
    component: () => import("../views/PaymentView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the route has a hash (anchor), scroll to it
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    // If the route has saved position (e.g., back/forward navigation)
    if (savedPosition) {
      return savedPosition;
    }
    // Default scroll to top behavior
    return { x: 0, y: 0 };
  },
});

export default router;
