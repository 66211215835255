
import { defineComponent } from "vue";
import MarqueeText from "vue-marquee-text-component";

export default defineComponent({
  props: {
    mainPage: Boolean,
    content: String,
  },
  name: "Marquee",
  components: {
    MarqueeText,
  },
});
