
import { defineComponent } from "vue";
import Card3 from "../Shared/Cards/Card3.vue";

export default defineComponent({
  components: { Card3 },
  props: {
    mainPage: Boolean,
    content: String,
  },
  name: "LatestUpdates",
});
