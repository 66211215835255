
import { defineComponent } from "vue";
import CustomSelect from "../Shared/CustomSelect.vue";
import LocationDropdown from "../Shared/LocationDropdown.vue";

export default defineComponent({
  name: "HeroSection1",
  components: {
    LocationDropdown,
    CustomSelect,
  },
  data() {
    return {
      selectedItems: [],
      activeTab: "local",
      show: false,
      itemList: [
        {
          name: "Vue.js",
          language: "Javascript",
        },
      ],
    };
  },
  methods: {
    setActive(tab: string) {
      this.activeTab = tab;
    },
  },
});
