import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { className: "w-full sm:w-[390px] md:w-[32%] lg:mb-0 mb-7 bg-white sm:h-[269px] h-[fit-content] rounded-[12px] p-10 flex flex-col justify-between" }
const _hoisted_2 = { className: "text-center sm:text-left m-auto sm:m-[initial]" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { className: "text-[#02021E] sm:text-[22px] text-[1.2rem] my-1" }
const _hoisted_5 = { className: "text-[#617686] text-[16px] pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        className: "object-contain",
        src: require(`@/assets/images/${_ctx.src_image}`),
        alt: "divIcon1"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.content), 1)
  ]))
}