
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    mainPage: Boolean,
    content:String,
  },
  name: "WhoWeAre",
  components: {},
});
