
import { defineComponent } from "vue";
import {
  Accordion,
  AccordionPanel,
  AccordionHeader,
  AccordionContent,
} from "flowbite-vue";

export default defineComponent({
  props: {
    mainPage: Boolean,
    content: String,
  },
  name: "FAQ",
  components: {
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
  },
});
