export const user = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard/User/Overview.vue"),
  },
  {
    path: "/account_manager",
    name: "account_manager",
    component: () => import("../views/Dashboard/User/AccountManager.vue"),
  },
  {
    path: "/settlement",
    name: "settlement",
    component: () => import("../views/Dashboard/User/SettlementIn.vue"),
  },
  {
    path: "/subscription",
    name: "subscription",
    component: () => import("../views/Dashboard/User/Subscription.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Dashboard/User/Profile.vue"),
  },
  {
    path: "/affiliates",
    name: "affiliates",
    component: () => import("../views/Dashboard/User/Affiliate.vue"),
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () => import("../views/Dashboard/User/Wallet.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/Dashboard/User/Settings.vue"),
  },
  {
    path: "/order_step1",
    name: "order1",
    component: () => import("../views/Dashboard/User/PartnerReg1.vue"),
  },
  {
    path: "/order_step2",
    name: "order2",
    component: () => import("../views/Dashboard/User/CreateOrderStep2.vue"),
  },
  {
    path: "/order_step3",
    name: "order3",
    component: () => import("../views/Dashboard/User/CreateOrderStep3.vue"),
  },
  {
    path: "/order_step4",
    name: "order4",
    component: () => import("../views/Dashboard/User/CreateOrderStep4.vue"),
  },
  {
    path: "/order_step5",
    name: "order5",
    component: () => import("../views/Dashboard/User/CreateOrderStep5.vue"),
  },
  {
    path: "/order_details",
    name: "order_details",
    component: () => import("../views/Dashboard/User/OrderDetails.vue"),
  },
];
