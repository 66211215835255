<template>
  <button :type="type" :style="styles" class="bg-yellow-400 rounded-full text-white border-none px-4 py-2 cursor-pointer font-semibold">
    {{title}}
  </button>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    styles: Object,
    type: String,
    title:String,
  },
};
</script>