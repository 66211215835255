import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full sm:w-[390px] md:w-[32%] lg:mb-0 mb-7 bg-white sm:h-[220px] h-[fit-content] rounded-[12px] p-7 text-left" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "!m-[0px] pl-[4px] flex flex-col !ml-1" }
const _hoisted_5 = { class: "text-[#02021E] leading-[1] !mb-0 sm:text-[22px] text-[1.2rem]" }
const _hoisted_6 = { class: "pl-3 sm:text-[15px] text-[12px] text-[#617686] leading-[1]" }
const _hoisted_7 = { class: "text-[#02021E] text-[16px] font-medium pb-3 !mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "object-contain",
        src: require(`@/assets/images/${_ctx.src_image}`),
        alt: "divIcon1"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.subTitle), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.content), 1)
  ]))
}